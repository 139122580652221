<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">Based on GHS information, which chemical is more hazardous?</p>

      <v-simple-table>
        <thead>
          <tr>
            <td style="font-weight: bold">Chemical</td>
            <td style="font-weight: bold">Signal Word</td>
            <td style="font-weight: bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Hazard & Category</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>sodium hypochlorite (bleach)</td>
            <td>danger</td>
            <td>
              <ul style="list-style-type: none">
                <li>serious eye damage (Category 1)</li>
                <li>skin corrosion (Category 1B)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>ethylene glycol (antifreeze)</td>
            <td>warning</td>
            <td>
              <ul style="list-style-type: none">
                <li>acute toxicity, oral (Category 4)</li>
                <li>specific organ toxicity (Category 2)</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-radio-group v-model="inputs.studentAnswer" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="option in optionsShuffled"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
      <p class="mb-2 mt-4">I think that because:</p>

      <p class="mb-n3">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI_1LC_Q1S_Q9',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: 'sodium hypochlorite', value: 'sodiumHypochlorite'},
        {text: 'ethylene glycol', value: 'ethyleneGlycol'},
        {text: 'Not enough information to determine.', value: 'notEnoughInformation'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
